import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Юридична фірма LegalEagle
			</title>
			<meta name={"description"} content={"Ваш надійний партнер у сфері права"} />
			<meta property={"og:title"} content={"Головна | Юридична фірма LegalEagle"} />
			<meta property={"og:description"} content={"Ваш надійний партнер у сфері права"} />
			<meta property={"og:image"} content={"https://map.quanticors.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://map.quanticors.com/img/3898103.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://map.quanticors.com/img/3898103.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://map.quanticors.com/img/3898103.png"} />
			
			
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://map.quanticors.com/img/1.jpg) 0% 0% /cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="--headline1"
				letter-spacing="1px"
				color="#6d32ec"
				padding="0px 150px 0px 150px"
				lg-padding="0px 0 0px 0"
				text-transform="uppercase"
			>
				Юридична фірма LegalEagle
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 50px 0px 50px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Юридична фірма LegalEagle пишається тим, що надає виняткові юридичні послуги, адаптовані до унікальних потреб наших клієнтів. Наша команда досвідчених юристів прагне надавати комплексні юридичні рішення з професіоналізмом, доброчесністю та відданістю справі. 
			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://map.quanticors.com/img/2.jpg) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
				color="--light"
			>
				<Box
					width="60%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--light"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Про фірму LegalEagle
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
					Юридична фірма LegalEagle надає широкий спектр юридичних послуг у різних галузях права. Наша місія полягає у забезпеченні першокласного юридичного представництва та консультування приватних осіб, підприємств та організацій. Маючи багаторічний досвід та успішний послужний список, наші юристи привносять багатство знань та експертизи в кожну справу. Ми розуміємо, що ситуація кожного клієнта є унікальною, і прагнемо запропонувати персоналізовані рішення, які відповідають його конкретним потребам та цілям.
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						1
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Експертна команда юристів
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наші юристи є висококваліфікованими та обізнаними у своїх галузях, що гарантує вам найкраще юридичне представництво.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						2
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Персоналізоване обслуговування
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми знайдемо час, щоб зрозуміти вашу унікальну ситуацію і адаптуємо наші послуги до ваших конкретних потреб.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						3
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Перевірена репутація
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Маючи історію успішного вирішення справ, ви можете бути впевнені, що ми маємо досвід і знання, необхідні для ефективного вирішення ваших юридичних питань.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 32px 0px"
						font="--headline3"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
						background="--color-indigo"
						sm-margin="0px 0px 20px 0px"
						lg-margin="0px 0px 18px 0px"
					>
						4
					</Text>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Комплексні рішення
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Від першої консультації до остаточного вирішення справи, ми надаємо комплексну юридичну підтримку на кожному етапі.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});